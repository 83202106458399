<script setup lang="ts">
import type { DepositStreakInfo } from "@/types";

const { t } = useT();

const props = defineProps<{
	depositInfo: DepositStreakInfo;
	finishedAt: string;
	resetStreakAt: string;
}>();

const { open } = useFunrizeModals();
const { ready: readyAnimation, start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

const handleClick = () => {
	if (props.depositInfo?.dailyWinStreakLimitExceeded) {
		handleStartAnimation();
		handleStartPopper();
		return;
	}

	if (props.depositInfo?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/deposit-streak/promo.jpg',
			alt: 'prize-logo',
			format: 'avif'
		}"
		height="257px"
		@click="handleClick"
	>
		<template #top>
			<div :class="['block', { top: depositInfo.canWheel }]">
				<AText
					v-if="depositInfo.dailyWinStreakLimitExceeded"
					variant="ternopil"
					:modifiers="['uppercase']"
					class="time top"
				>
					<i18n-t keypath="Available in {key}">
						<template #key>
							<span>{{ resetStreakAt }}</span>
						</template>
					</i18n-t>
				</AText>
				<AText v-else-if="!depositInfo.canWheel" variant="ternopil" :modifiers="['uppercase']" class="time">
					{{ t("Ends in") }} {{ finishedAt }}
				</AText>
				<AText v-if="depositInfo.canWheel" as="div" variant="tanta" :modifiers="['bold']" class="title text-cixi">
					{{ t("Prize Machine Unlocked") }}
				</AText>
				<AText v-else as="div" variant="tanta" :modifiers="['bold']" class="title">
					{{ t("Prize Machine, Boost Your Purchases!") }}
				</AText>
				<LazyMDepositStreakProgressBar
					v-if="!depositInfo.dailyWinStreakLimitExceeded"
					class="promo-progress"
					:steps="depositInfo.progress"
				>
					<template #bottom>
						<AText class="text-conakry" as="div">
							<i18n-t keypath="{key} Purchases Completed">
								<template #key>
									{{ `${depositInfo?.currentCountDeposit}/${depositInfo?.maxNumberOfDeposit}` }}
								</template>
							</i18n-t>
						</AText>
					</template>
				</LazyMDepositStreakProgressBar>
			</div>
		</template>
		<template #bottom>
			<APopper
				v-if="depositInfo.dailyWinStreakLimitExceeded"
				:content="t('Available tomorrow')"
				placement="top"
				:show="!readyPopper"
			>
				<AButton variant="disabled" data-tid="promo-available" size="md">
					<AAnimationTada :active="!readyAnimation">
						<AText variant="tulsa">
							<NuxtIcon :name="`20/clock`" filled />
						</AText>
					</AAnimationTada>
					<AText :modifiers="['bold', 'uppercase']">{{ t("Available tomorrow") }}</AText>
				</AButton>
			</APopper>
			<AButton v-else variant="primary" size="md" data-tid="promo-open-store">
				<AText variant="toledo" :modifiers="['bold', 'uppercase']">
					{{ depositInfo.canWheel ? t("Get prize") : t("Open store") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-count {
	display: flex;
	align-items: center;
	gap: 4px;
}

button {
	width: 240px;
	margin-top: 4px;

	.nuxt-icon {
		margin-right: 4px;
	}
}

.block {
	margin-top: -16px;

	&.top {
		margin-top: 0;
	}
}

.top-left {
	position: absolute;
	top: 0;
	left: 0;
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.time {
		position: absolute;
		top: 26px;
		left: 20px;
		max-width: 100%;
	}

	.title {
		min-height: 15px;
		margin-bottom: 16px;
		max-width: 176px;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: 8px;
}

.promo-progress.progress {
	width: 240px;
}
</style>
